<template>
  <div class="LicensePage">
    <AppBarLayout>
      <template #header>
        {{ $t('license.License') }}
      </template>
    </AppBarLayout>

    <LicenseInformation class="LicensePage__info" />
    <LicensePlanTable
      v-if="!isMaxLicense"
      class="LicensePage__plan-table"
    />
    <LicensePageInlinePanel
      v-if="counters != null && currentLicense != null"
      class="LicensePage__details"
    />
  </div>
</template>

<script>
import LicenseInformation from '../components/LicenseInformation'
import LicensePlanTable from '../components/LicensePlanTable'
import LicensePageInlinePanel from '../components/LicensePageInlinePanel'
import AppBarLayout from '../layouts/AppBarLayout'

export default {
  name: 'LicensePage',

  components: {
    AppBarLayout,
    LicenseInformation,
    LicensePlanTable,
    LicensePageInlinePanel,
  },

  computed: {
    currentLicense() { return this.$store.state.license.current },
    isMaxLicense() { return this.$store.getters['license/isMaxLicense'] },
    counters() { return this.$store.getters['license/combinedCounters'] },
  },

  created() {
    this.$store.dispatch('license/getFull')
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.LicensePage
  display: grid
  grid-template-rows: min-content 1fr
  grid-template-columns: 1fr min-content
  height: calc(100vh - 56px)

  @media #{map-get($display-breakpoints, 'md-and-up')}
    height: calc(100vh - 64px)

  @media #{map-get($display-breakpoints, 'xl-only')}
    grid-template-columns: 1fr 540px

  &__info
    grid-column: 1
    grid-row: 1

  &__plan-table
    grid-column: 1
    grid-row: 2

  &__details
    grid-column: 2
    grid-row: 1 / -1
    max-width: 540px
</style>
