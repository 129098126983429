<template>
  <div
    class="LicenseFeatureProgressByProject"
    :class="{
      'LicenseFeatureProgressByProject--empty': currentCount === 0,
      'LicenseFeatureProgressByProject--has-limit': currentMaxCount != null,
      'LicenseFeatureProgressByProject--show-progress': showProgress,
    }"
  >
    <AppDashed
      class="LicenseFeatureProgressByProject__info"
      :connection-color="maxCountExtended == null ? undefined : 'transparent'"
    >
      <template #title>
        <div class="LicenseFeatureProgressByProject__info-name">
          {{ projectName }}
        </div>
      </template>
      <template #value>
        <div class="LicenseFeatureProgressByProject__info-counter">
          {{ currentCount }}<span v-if="currentMaxCount != null">/{{ currentMaxCount }}</span>
        </div>
      </template>
      <template #append>
        <v-tooltip
          v-if="licenseHasExpired && (
            expiredMaxCount == null ||
            (currentMaxCount != null && currentMaxCount < expiredMaxCount)
          )"
          bottom
          z-index="20"
          color="#424266"
        >
          <template #activator="{ attrs, on }">
            <v-hover v-slot="{ hover }">
              <div class="LicenseFeatureProgressByProject__info-renew">
                <v-btn
                  class="LicenseFeatureProgressByProject__info-btn"
                  width="auto"
                  v-bind="attrs"
                  text
                  plain
                  :color="hover ? '#0088FF' : 'rgba(102, 102, 153, 0.61)'"
                  v-on="on"
                  @click="openLicenseDialog"
                >
                  <v-icon
                    small
                    size="20"
                  >
                    {{ hover ? 'mdi-lock-open-outline' : 'mdi-lock-outline' }}
                  </v-icon>
                  <span>{{ expiredMaxCount == null ? $t('license.unlimited') : expiredMaxCount }}</span>
                </v-btn>
              </div>
            </v-hover>
          </template>
          <span>
            {{ $t('license.RenewLicenseToGetItemsBackM', {expiredMaxCount: expiredMaxCount == null ? $t('license.unlimitedNumberOf') : expiredMaxCount, name: $t(name).toLowerCase()}) }}
          </span>
        </v-tooltip>
      </template>
    </AppDashed>
    <div
      v-if="showProgress"
      class="LicenseFeatureProgressByProject__progress"
    >
      <v-progress-linear
        rounded
        color="blue lighten-4"
        :background-color="licenseHasExpired ? '#C1C1D2' : 'rgba(0, 0, 0, 0.06)'"
        :value="limitedPercent"
        class="LicenseFeatureProgressByProject__progress-linear"
        :class="{ stripped: licenseHasExpired }"
        :buffer-value="expiredPercent"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Project from '../store/orm/project'

export default {
  name: 'LicenseFeatureProgressByProject',

  props: {
    name: { type: String, required: true },
    techName: { type: String, required: true },
    projectId: { type: String, required: true },
    // see type `PerProjectCounter`
    perProjectCounter: { type: Object, required: true },
    expiredCounter: { type: Object, required: true },
    // key of `PerProjectCounter` instance
    // e.g.: "applicationCounter"
    counterName: { type: String, required: true },
  },

  computed: {
    ...mapState({
      currentLicense: 'license/current',
    }),

    projectName() {
      const { projectId } = this
      return Project.find(projectId)?.name ?? '...'
    },

    currentCount() {
      const { perProjectCounter, counterName } = this
      return perProjectCounter[counterName].currentCount
    },

    expiredCount() {
      const { expiredCounter, counterName } = this
      return expiredCounter[counterName].currentCount
    },

    expiredMaxCount() {
      const { expiredCounter, counterName } = this
      return expiredCounter[counterName]?.maxCount
    },

    currentMaxCount() {
      const { perProjectCounter, counterName } = this
      return perProjectCounter[counterName]?.maxCount
    },

    maxCountExtended() {
      const { expiredMaxCount, currentMaxCount, currentCount } = this
      if (expiredMaxCount != null && currentCount != null) {
        return Math.max(expiredMaxCount, currentMaxCount ?? 0, currentCount)
      }
      return expiredMaxCount ?? currentMaxCount
    },

    percent() {
      const { currentCount, maxCountExtended: maxCount } = this

      if (maxCount == null) return 50
      if (!currentCount || maxCount === 0) return 0

      return currentCount * 100 / maxCount
    },

    expiredPercent() {
      const { expiredCount, maxCountExtended: maxCount } = this

      if (maxCount == null) return 50
      if (!expiredCount || maxCount === 0) return 0

      return expiredCount * 100 / maxCount
    },

    limitedPercent() {
      const { percent, currentMaxCount, maxCountExtended: maxCount } = this
      if (currentMaxCount == null) return 50
      return Math.min(percent, currentMaxCount * 100 / maxCount)
    },

    licenseHasExpired() {
      return this.$store.getters['license/isExpired']
    },

    showProgress() {
      const { maxCountExtended } = this
      return maxCountExtended != null
    },
  },

  methods: {
    openLicenseDialog() {
      this.$store.commit('license/setLicenseDialog', {
        isOpen: true,
        featureName: this.name,
      })
    },
  },
}
</script>

<style lang="sass">
.LicenseFeatureProgressByProject
  display: flex
  justify-content: center
  flex-direction: column
  height: 56px
  width: 100%
  padding: 0 (36px + 12 + 20) 0 36px
  font-size: 12px

  &__info
    width: 100%
    line-height: 16px
    height: 20px
    display: flex
    align-items: center

  &--show-progress .AppDashed__title
    flex: 1

  &__info-name
    min-width: 12px
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  &--show-progress &__info-name
    flex: 0 1 102px

  &__info-counter
    font-size: 14px
    font-weight: 500
    min-width: 36px
    text-align: center

  &--empty#{&}--has-limit &__info-counter
    color: #D3D6E2

  &__info-renew
    flex: 1 0 102px + 16px
    margin-right: -16px
    display: flex
    justify-content: flex-end

  &__info-btn
    font-size: 13px
    &:hover
      color: #0088FF

  &__progress
    margin-top: 7px

  &__progress-linear
    background: rgba(0, 0, 0, 0.06)
</style>
