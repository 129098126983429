<template>
  <div class="LdapConfig">
    <AppBarLayout>
      <template #header>
        {{ $t('service.LdapConnection') }}
      </template>
    </AppBarLayout>

    <div class="LdapConfig__page">
      <div
        class="LdapConfig__switch"
      >
        <AppSwitch
          :key="switchKey"
          :input-value="useLdap"
          class="ma-0 pa-0"
          :label="$t('service.LdapConnection')"
          :disabled="disabled"
          hide-details
          @change="($event ? enableLdap() : disableLdap()).then(() => ++switchKey)"
        />
        <span
          class="text--secondary d-inline-block"
          v-text="useLdap ? $t('service.On') : $t('service.Off')"
        />
      </div>

      <v-data-table
        v-if="hasConfig && ldapConfig.enabled"
        class="LdapConfig__table"
        :headers="TABLE_HEADERS"
        :items="[ldapConfig]"
        :items-per-page="-1"
        :loading="ldapConfig === LDAP_NOT_LOADED"
        item-key="host"
        fixed-header
        hide-default-footer
        disable-sort
      >
        <template #item="{ item: ldapConfig }">
          <tr>
            <td
              v-for="prop in ['host', 'port', 'baseDN']"
              :key="prop"
            >
              <router-link
                v-ripple="{ class: 'app-ripple' }"
                class="LdapConfig__table-cell"
                :to="{ name: 'LdapConfigEdit' }"
              >
                {{ ldapConfig[prop] }}
              </router-link>
            </td>
            <td>
              <v-btn
                text
                tile
                color="primary"
                :to="{ name: 'LdapConfigEdit' }"
                class="LdapConfig__table-action"
              >
                {{ $t('service.EditConfig') }}
              </v-btn>
              <v-btn
                text
                tile
                color="error"
                class="LdapConfig__table-action"
                @click="deleteLdapConfig"
              >
                {{ $t('service.Delete') }}
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { LDAP_MISSING, LDAP_NOT_LOADED } from '../constants'
import { pushRoute } from '../helpers'

import AppBarLayout from '../layouts/AppBarLayout'

export default {
  name: 'LdapConfig',

  components: {
    AppBarLayout,
  },

  metaInfo() {
    return {
      title: this.$store.getters.title(this.$t('service.Ldap')),
    }
  },

  data() {
    return {
      LDAP_MISSING,
      LDAP_NOT_LOADED,
      TABLE_HEADERS: Object.freeze([
        Object.freeze({ text: this.$t('service.TableHost'), value: 'host' }),
        Object.freeze({ text: this.$t('service.TablePort'), value: 'port' }),
        Object.freeze({ text: this.$t('service.TableBaseDn'), value: 'baseDN' }),

        // Actions column
        Object.freeze({
          value: '_actions',
          sortable: false,
          align: 'end',
        }),
      ]),

      useLdap: false,
      saving: false,
      switchKey: Number.MIN_SAFE_INTEGER,
    }
  },

  computed: {
    ldapConfig() { return this.$store.state.service.ldapConfig },

    hasConfig() {
      return ![LDAP_MISSING, LDAP_NOT_LOADED].includes(this.ldapConfig)
    },

    disabled: {
      get() {
        const { ldapConfig, saving } = this
        return saving || ldapConfig === LDAP_NOT_LOADED
      },
      set(disabled) { this.saving = !!disabled },
    },
  },

  watch: {
    hasConfig: {
      handler(hasConfig) {
        this.useLdap = hasConfig && this.ldapConfig.enabled
      },
      immediate: true,
    },
  },

  created() {
    this.$store.dispatch('service/getLdapConfig')
  },

  methods: {
    async enableLdap() {
      const { $store, $router, ldapConfig, disabled } = this

      if (!this.hasConfig) {
        return await pushRoute($router, { name: 'LdapConfigEdit' })
      }

      if (disabled) throw new Error('operation is already in progress')
      if (ldapConfig === LDAP_MISSING) throw new Error('no LDAP configuration')

      this.disabled = true
      try {
        await $store.dispatch('service/updateLdapConfig', {
          ldapConfig: { enabled: true },
        })
        this.useLdap = true
      } finally {
        this.disabled = false
      }
    },

    async disableLdap() {
      const { $store, ldapConfig, disabled } = this

      if (disabled) throw new Error('operation is already in progress')
      if (ldapConfig === LDAP_MISSING) throw new Error('no LDAP configuration')

      if (!(await $store.dispatch('confirm/openDialog', {
        title: this.$t('service.DisableLdapQ'),
        subtitle: this.$t('service.LocalAuthAvailableM'),
      }))) return

      this.disabled = true
      try {
        await $store.dispatch('service/updateLdapConfig', {
          ldapConfig: { enabled: false },
        })
        this.useLdap = false
      } finally {
        this.disabled = false
      }
    },

    async deleteLdapConfig() {
      const { $store, ldapConfig, disabled } = this

      if (disabled) throw new Error('operation is already in progress')
      if (ldapConfig === LDAP_MISSING) throw new Error('no LDAP configuration')

      if (!(await $store.dispatch('confirm/openDialog', {
        title: this.$t('service.RemoveLdapConfigQ'),
        subtitle: this.$t('service.RemoveLdapConfigM'),
      }))) return

      this.disabled = true
      try {
        await $store.dispatch('service/deleteLdapConfig')
        this.useLdap = false
      } finally {
        this.disabled = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.LdapConfig
  padding: 64px
  display: flex
  justify-content: center
  background: #F5F5F9
  height: 100%

  &__page
    flex: 0 1 1152px

  &__switch
    margin: 28px 0
    display: flex
    align-items: center

  &__table
    border-radius: 8px
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2)

    ::v-deep
      @media #{map-get($display-breakpoints, 'md-and-up')}
        table
          table-layout: fixed

      .v-data-table__wrapper
        border-radius: 8px

        thead.v-data-table-header, tbody
          > tr > th, > tr > td
            white-space: nowrap

          > tr > th
            padding: 0 24px
            font-size: 13px
            line-height: 24px
            color: #38364D // Text / primary

          > tr > td
            padding: 0

        thead.v-data-table-header
          > tr > th
            height: 40px

  &__table-cell
    display: flex
    align-items: center
    padding: 24px
    height: 72px
    font-size: 14px
    line-height: 24px
    color: #8492A6 // Text / secondary
    text-decoration: inherit
    overflow: hidden

  &__table-action
    font-weight: normal
    font-size: 13px
    line-height: 20px
</style>
