<template>
  <div
    v-show="!(hideWhenActive && !featureBlocked)"
    :key="redrawKey"
    class="LicenseFeatureProgress"
  >
    <v-hover v-slot="{ hover }">
      <div
        class="LicenseFeatureProgress__item"
        :class="{ 'LicenseFeatureProgress__item--clickable': isFoldable || featureBlocked }"
        :tabindex="isFoldable || featureBlocked ? '0' : '-1'"
        :role="isFoldable || featureBlocked ? 'button' : 'generic'"
        @click="onItemClick"
        @keydown.enter="onItemClick"
      >
        <div
          class="LicenseFeatureProgress__title"
          :class="{ 'LicenseFeatureProgress__title--dashed': !showProgress }"
        >
          <span
            class="LicenseFeatureProgress__name"
            v-text="$t(name)"
          />

          <span class="LicenseFeatureProgress__dashed-connection" />

          <v-tooltip
            v-if="(perProject || currentCount != null || maxCount != null) && !featureBlocked"
            bottom
          >
            <template #activator="{ on, attrs }">
              <span
                v-if="perProject"
                class="LicenseFeatureProgress__counter"
                v-bind="attrs"
                v-on="on"
              >
                {{ childrenCountSum }}
              </span>
              <span
                v-else-if="currentCount != null || maxCount != null"
                class="LicenseFeatureProgress__counter"
                v-bind="attrs"
                v-on="on"
              >
                {{ currentCount }}{{ maxCount != null ? ('/' + maxCount) : '' }}
              </span>
            </template>
            <span>{{ $t('license.Used') }}</span>
          </v-tooltip>
          <v-tooltip
            v-else-if="featureBlocked"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                class="LicenseFeatureProgress__blocked"
                v-bind="attrs"
                tabindex="-1"
                v-on="on"
                @click.stop="openLicenseDialog()"
                @keydown.stop
              >
                <v-icon
                  :color="hover ? 'primary' : 'rgba(102, 102, 153, 0.38)'"
                  v-text="hover ? 'mdi-lock-open-outline' : 'mdi-lock-outline'"
                />
              </v-btn>
            </template>
            <span v-text="$t('license.GetFeature')" />
          </v-tooltip>

          <span class="LicenseFeatureProgress__expand">
            <v-btn
              v-if="isFoldable"
              icon
              class="LicenseFeatureProgress__expand-btn"
              tabindex="-1"
              @click.stop="isFoldedInner = !isFoldedInner"
              @keydown.stop
            >
              <v-icon
                small
                color="#D3D6E2"
                v-text="isFoldedInner ? 'mdi-chevron-down' : 'mdi-chevron-up'"
              />
            </v-btn>
          </span>
        </div>

        <div
          v-if="showProgress"
          class="LicenseFeatureProgress__progress"
        >
          <v-progress-linear
            class="LicenseFeatureProgress__progress-linear"
            rounded
            :value="percent"
            :buffer-value="expiredPercent"
            color="blue lighten-4"
          />
        </div>

        <div
          v-if="addMoreBtn && maxCount != null"
          class="LicenseFeatureProgress__add-more"
        >
          <v-btn
            text
            plain
            class=""
            color="primary"
            @click="openLicenseDialog()"
          >
            {{ $t('license.AddItems', { itemName: $t(name).toLowerCase()}) }}
          </v-btn>
        </div>
      </div>
    </v-hover>

    <v-expand-transition>
      <div
        v-if="!showProgress && !isFoldedInner"
        class="LicenseFeatureProgress__nested-items"
      >
        <LicenseFeatureProgressByProject
          v-for="perProjectCounter in slicedLicenseCounterProjects"
          :key="perProjectCounter.projectId"
          class="LicenseFeatureProgress__nested-item"
          :name="name"
          :tech-name="techName"
          :counter-name="counterName"
          :project-id="perProjectCounter.projectId"
          :per-project-counter="perProjectCounter.current"
          :expired-counter="perProjectCounter.expired"
        />

        <div
          v-if="projectCounters && projectCounters.length > 3 && !showAll"
          class="LicenseFeatureProgress__show-more"
        >
          <v-btn
            text
            plain
            color="#0088FF"
            @click="showAll = true"
          >
            {{ $t('license.ShowMore') }}
          </v-btn>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import * as R from 'ramda'

import { PENTEST_PLAN, CORPORATE_PLAN, PLAN_TABLE, UNLIMITED_IN_PLAN } from '../constants'

import LicenseFeatureProgressByProject from './LicenseFeatureProgressByProject'

export default {
  name: 'LicenseFeatureProgress',

  components: {
    LicenseFeatureProgressByProject,
  },

  props: {
    // Feature fields, from `constants.PLAN_TABLE`
    name: { type: String, required: true },
    techName: { type: String, required: true },
    counterName: { type: String, default: null },
    perProject: { type: Boolean, default: false },

    featureBlocked: { type: Boolean, default: false },
    hideWhenActive: { type: Boolean, default: false },
    addMoreBtn: { type: Boolean, default: false },

    isFolded: { type: Boolean, default: true }, // .sync
  },

  data() {
    return {
      isFoldedInner: true,
      showAll: false,
      CORPORATE_PLAN,
      PENTEST_PLAN,
    }
  },

  computed: {
    currentLicense() { return this.$store.state.license.current },
    counters() { return this.$store.getters['license/combinedCounters'] },

    maxCount() {
      const { counters, currentLicense, techName, counterName, perProject } = this
      if (!counters || !currentLicense || counterName == null) return null
      return perProject
        ? (() => {
          // FIXME: Where do we get this info from back-end?
          const supposedMaxCount = PLAN_TABLE.find(f => f.techName === techName)?.[currentLicense.licenseType]
          if (supposedMaxCount === UNLIMITED_IN_PLAN) return null
          const asInt = parseInt(supposedMaxCount, 10)
          return Number.isNaN(asInt) ? null : asInt
        })()
        : counters.commonParameters[counterName]?.expired?.maxCount
    },

    showProgress() {
      const { perProject, maxCount, featureBlocked } = this
      return !featureBlocked && !perProject && maxCount != null
    },

    currentCount() {
      const { counters, counterName, perProject } = this
      if (!counters || perProject || counterName == null) return null
      return counters.commonParameters[counterName]?.current?.currentCount
    },

    expiredCount() {
      const { counters, counterName, perProject } = this
      if (!counters || perProject || counterName == null) return null
      return counters.commonParameters[counterName]?.expired?.currentCount
    },

    percent() {
      const { currentCount, maxCount } = this

      if (!maxCount || currentCount == null) return 0
      return currentCount ? currentCount * 100 / maxCount : 0
    },

    expiredPercent() {
      const { expiredCount, maxCount } = this

      if (!maxCount || expiredCount == null) return 0
      return expiredCount ? expiredCount * 100 / maxCount : 0
    },

    projectCounters() {
      const { counters } = this
      return counters && Object.entries(counters.projectParameters)
        .map(([projectId, counter]) => ({ projectId, ...counter }))
    },

    isFoldable() {
      const { perProject, projectCounters } = this
      return perProject && (projectCounters?.length ?? 0) > 0
    },

    slicedLicenseCounterProjects() {
      const { projectCounters, showAll } = this
      if (!projectCounters) return null

      if (projectCounters.length > 3 && !showAll) return projectCounters.slice(0, 3)
      return projectCounters
    },

    childrenCountSum() {
      const { projectCounters, counterName } = this
      if (!projectCounters || counterName == null) return null
      const counts = R.pipe(
        R.map(pc => pc.current?.[counterName]?.currentCount),
        R.filter(count => count != null),
      )(projectCounters)
      return R.sum(counts)
    },

    redrawKey() {
      const { currentLicense, counters } = this
      // hack to fix missing elements caused by overusing
      // v-if/v-else-if on templates, menus, tooltips
      return JSON.stringify({ currentLicense, counters })
    },
  },

  watch: {
    isFoldedInner(isFolded) {
      this.showAll = false
      if (isFolded !== this.isFolded) {
        this.$emit('update:isFolded', !!isFolded)
      }
    },
    isFolded(isFolded) {
      if (isFolded !== this.isFoldedInner) this.isFoldedInner = isFolded
    },
    'currentLicense.id'() { this.isFoldedInner = true },
  },

  methods: {
    openLicenseDialog() {
      this.$store.commit('license/setLicenseDialog', {
        isOpen: true,
        featureName: this.name,
        countable: !this.featureBlocked, // fixme: it's not the best decision, but works for now
      })
    },

    onItemClick() {
      const { featureBlocked, isFoldable } = this
      if (isFoldable) {
        this.isFoldedInner = !this.isFoldedInner
      } else if (featureBlocked) {
        this.openLicenseDialog()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.LicenseFeatureProgress
  width: 100%
  border-bottom: 1px solid rgba(0, 0, 0, 0.06)
  overflow: hidden

  &__item
    padding: 24px 36px

    &--clickable
      &:hover, &:active, &:focus
        background: #FAFAFC
  &__title
    display: flex
    font-weight: 500
    font-size: 16px
    line-height: 36px

  &__title:not(&__title--dashed) &__name
    margin-right: auto

  &__name
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    min-width: 40px

  &__dashed-connection
    display: none
    flex: 1
    border-bottom: 1px dashed #C1C1D2
    align-self: center
    height: 2ex

  &__title--dashed &__dashed-connection
    display: inline-block

  &__counter
    min-width: 36px
    text-align: center
    flex: 0 0 auto

  &__blocked
    display: flex
    align-items: center
    justify-content: center
    width: 36px
    height: 36px
    flex: 0 0 auto

  &__expand
    align-self: center
    display: inline-flex
    align-items: center
    justify-content: center
    margin-left: 12px
    width: 20px
    height: 20px
    flex: 0 0 20px

  // &__nested-items
  //   background: #fee

  &__progress
    padding-right: 32px

  &__progress-linear
    background: rgba(0, 0, 0, 0.06)

  &__add-more
    margin-left: -16px
    padding-top: 6px

  &__add-more, &__show-more
    height: 48px

    .v-btn
      font-size: 13px
      line-height: 16px

    ::v-deep .v-btn__content
      line-height: 16px

  &__show-more
    padding: 0 16px
</style>
