var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"LicenseFeatureProgressByProject",class:{
    'LicenseFeatureProgressByProject--empty': _vm.currentCount === 0,
    'LicenseFeatureProgressByProject--has-limit': _vm.currentMaxCount != null,
    'LicenseFeatureProgressByProject--show-progress': _vm.showProgress,
  }},[_c('AppDashed',{staticClass:"LicenseFeatureProgressByProject__info",attrs:{"connection-color":_vm.maxCountExtended == null ? undefined : 'transparent'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"LicenseFeatureProgressByProject__info-name"},[_vm._v(" "+_vm._s(_vm.projectName)+" ")])]},proxy:true},{key:"value",fn:function(){return [_c('div',{staticClass:"LicenseFeatureProgressByProject__info-counter"},[_vm._v(" "+_vm._s(_vm.currentCount)),(_vm.currentMaxCount != null)?_c('span',[_vm._v("/"+_vm._s(_vm.currentMaxCount))]):_vm._e()])]},proxy:true},{key:"append",fn:function(){return [(_vm.licenseHasExpired && (
          _vm.expiredMaxCount == null ||
          (_vm.currentMaxCount != null && _vm.currentMaxCount < _vm.expiredMaxCount)
        ))?_c('v-tooltip',{attrs:{"bottom":"","z-index":"20","color":"#424266"},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"LicenseFeatureProgressByProject__info-renew"},[_c('v-btn',_vm._g(_vm._b({staticClass:"LicenseFeatureProgressByProject__info-btn",attrs:{"width":"auto","text":"","plain":"","color":hover ? '#0088FF' : 'rgba(102, 102, 153, 0.61)'},on:{"click":_vm.openLicenseDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","size":"20"}},[_vm._v(" "+_vm._s(hover ? 'mdi-lock-open-outline' : 'mdi-lock-outline')+" ")]),_c('span',[_vm._v(_vm._s(_vm.expiredMaxCount == null ? _vm.$t('license.unlimited') : _vm.expiredMaxCount))])],1)],1)]}}],null,true)})]}}],null,false,2070985433)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('license.RenewLicenseToGetItemsBackM', {expiredMaxCount: _vm.expiredMaxCount == null ? _vm.$t('license.unlimitedNumberOf') : _vm.expiredMaxCount, name: _vm.$t(_vm.name).toLowerCase()}))+" ")])]):_vm._e()]},proxy:true}])}),(_vm.showProgress)?_c('div',{staticClass:"LicenseFeatureProgressByProject__progress"},[_c('v-progress-linear',{staticClass:"LicenseFeatureProgressByProject__progress-linear",class:{ stripped: _vm.licenseHasExpired },attrs:{"rounded":"","color":"blue lighten-4","background-color":_vm.licenseHasExpired ? '#C1C1D2' : 'rgba(0, 0, 0, 0.06)',"value":_vm.limitedPercent,"buffer-value":_vm.expiredPercent}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }