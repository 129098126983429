<template>
  <div
    class="LicenseInformation"
    :class="{
      'LicenseInformation--dragover': dragover,
      'LicenseInformation--expired': isExpiredLicense,
    }"
    @dragenter.prevent="dragover = true"
    @dragover.prevent="dragover = true"
    @dragleave="dragover = false"
    @drop.capture.prevent="addFiles"
  >
    <div class="LicenseInformation__plan">
      <h2 class="LicenseInformation__plan-name display-2 font-weight-medium">
        {{ $t('license.LicenseTypePlan', { licenseType:licenseType || 'Community'}) }}
      </h2>
      <AppDashed
        v-if="isExtendedLicense || isExpiredLicense"
        class="LicenseInformation__plan-customer"
        :title="$t('license.ClientName')"
        :value="customerName || ''"
      />
      <div
        v-if="isExtendedLicense || isExpiredLicense"
        class="LicenseInformation__license"
      >
        <h2 class="LicenseInformation__license-title">
          {{ $t('license.License') }}
        </h2>

        <v-btn
          icon
          class="ml-auto"
          @click="deleteLicense"
        >
          <v-icon color="error">
            mdi-delete-outline
          </v-icon>
        </v-btn>
      </div>
      <AppDashed
        v-if="startDate && (isExtendedLicense || isExpiredLicense)"
        class="LicenseInformation__plan-date"
        :title="$t('license.StartDate')"
        :value="moment(startDate).format('L')"
      />
      <AppDashed
        v-if="endDate && (isExpiredLicense || isExtendedLicense)"
        class="LicenseInformation__plan-date"
        :title="$t('license.EndDate')"
        :value="moment(endDate).format('L')"
      />
      <v-btn
        color="primary"
        outlined
        @click="openFileDialog"
      >
        {{ isExpiredLicense
          ? $t('license.UploadLicense')
          : (isExtendedLicense ? $t('license.ReplaceFile') : $t('license.UploadFile')) }}
      </v-btn>
    </div>

    <input
      ref="fileInput"
      type="file"
      style="position: absolute; top: -9999px; left: -9999px"
      tabindex="-1"
      accept=".lic"
      @change="addFiles"
    >
  </div>
</template>

<script>
import { LICENSE_PLAN } from '@/constants'
import moment from 'moment'

export default {
  name: 'LicenseInformation',

  data() {
    return {
      LICENSE_PLAN,

      dragover: false,
    }
  },

  computed: {
    currentLicense() { return this.$store.state.license.current },
    isExtendedLicense() { return this.$store.getters['license/isExtended'] },
    isExpiredLicense() { return this.$store.getters['license/isExpired'] },

    endDate() { return this.currentLicense?.endDate },
    startDate() { return this.currentLicense?.startDate },
    customerName() { return this.currentLicense?.customerName },
    licenseType() { return this.currentLicense?.licenseType },
  },

  methods: {
    moment: function(...args) {
      return moment(...args)
    },

    openFileDialog() {
      this.$refs.fileInput.click()
    },

    addFiles(e) {
      this.dragover = false
      const source = e.dataTransfer || e.target
      if (!source.files.length) return

      const { $store } = this
      $store
        .dispatch('license/post', { file: source.files[0] })
        .finally(() => { this.$refs.fileInput.value = '' })
    },

    async deleteLicense() {
      const { $store } = this

      const agreed = await $store.dispatch('confirm/openDialog', {
        title: this.$t('license.DeleteLicenseQ'),
        subtitle: this.$t('license.DeleteLicenseImmediateM'),
        consentLabel: this.$t('license.Delete'),
        consentProps: { color: 'error', depressed: true },
      })

      if (agreed) $store.dispatch('license/delete')
    },
  },
}
</script>

<style lang="sass">
$border-width: 8px

.LicenseInformation
  min-width: 400px
  padding: 32px 84px 82px

  border: $border-width dashed transparent
  transition: all 200ms ease

  &--dragover
    border-color: var(--v-primary-base, #2196F3)

  &__plan
    max-width: 334px

  &__plan-name
    margin-bottom: 28px
    &::first-letter
      text-transform: uppercase

  &__plan-customer
    display: flex
    align-items: flex-end
    font-size: 13px
    line-height: 16px
    /* identical to box height, or 160% */
    letter-spacing: 0.005em
    white-space: nowrap
    margin-bottom: 28px

  &__license
    display: flex
    align-items: center
    height: 32px
    margin-bottom: 28px

  &--expired &__license
    color: #EF5350

  &__license-title
    font-size: 20px
    line-height: 22px
    font-weight: 500

  &__plan-title
    margin-top: 28px
    font-size: 20px

  &__plan-date
    display: flex
    align-items: flex-end
    font-size: 13px
    margin-bottom: 28px
    white-space: nowrap
</style>
