<template>
  <div
    class="LicensePlanTable"
    @scroll.passive="scrollTop = $event.target.scrollTop"
  >
    <h2 class="mb-5">
      {{ $t('license.ComparisonPlans') }}
    </h2>

    <div class="LicensePlanTable__table">
      <div class="LicensePlanTable__feature">
        <div class="LicensePlanTable__feature-header">
          {{ $t('license.Feature') }}
        </div>
        <div
          v-for="item in PLAN_TABLE"
          :key="item.techName"
          class="LicensePlanTable__feature-name"
        >
          {{ $t(item.name) }}
        </div>
      </div>

      <div
        v-for="plan in LICENSE_PLAN"
        :key="plan.name"
        class="LicensePlanTable__plan"
        :class="{
          'LicensePlanTable__plan--active': licenseType === plan.name,
        }"
      >
        <div class="LicensePlanTable__plan-box">
          <div class="LicensePlanTable__plan-header">
            <span
              class="LicensePlanTable__plan-name"
              v-text="$t(plan.displayName)"
            />
            <span
              v-if="licenseType === plan.name"
              class="LicensePlanTable__plan-status"
            >
              {{ $t('license.CurrentPlan') }}
            </span>
            <span
              v-else-if="!plan.priceInfo"
              class="LicensePlanTable__plan-status"
            >
              {{ $t('license.plan') }}
            </span>
            <v-btn
              v-else-if="plan.priceInfo"
              color="primary"
              depressed
              class="LicensePlanTable__table-btn mt-2"
              href="https://hexway.io/pricing/#request-price"
              target="_blank"
            >
              {{ $t(plan.priceInfo) }}
            </v-btn>
          </div>

          <div
            v-for="item in PLAN_TABLE"
            :key="item.techName"
            class="LicensePlanTable__plan-item"
          >
            {{ $t(item[plan.name]) }}
            <sup
              v-if="isPricePerProject(item, plan.name)"
              class="LicensePlanTable__asterisk grey--text text--lighten-1"
              role="button"
              tabindex="0"
              @click="$refs.footnotes.scrollIntoView()"
              @keydown.enter="$refs.footnotes.scrollIntoView()"
            >
              *
            </sup>
          </div>
        </div>
      </div>
    </div>

    <div
      ref="footnotes"
      class="LicensePlanTable__footnotes"
    >
      <div class="LicensePlanTable__footnote">
        <span class="grey--text text--lighten-1">*</span>&mdash; {{ $t('license.perProject') }}
      </div>
    </div>
  </div>
</template>

<script>
import { PLAN_TABLE, LICENSE_PLAN, PENTEST_PLAN, CORPORATE_PLAN } from '../constants'

export default {
  name: 'LicensePlanTable',

  data() {
    return {
      PLAN_TABLE,
      LICENSE_PLAN,
      PENTEST_PLAN,
      CORPORATE_PLAN,

      scrollTop: 0,
    }
  },

  computed: {
    currentLicense() { return this.$store.state.license.current },
    licenseType() { return this.currentLicense?.licenseType },
  },

  methods: {
    isPricePerProject(planItem, planName) {
      const value = planItem[planName]
      return planItem.perProject && /^[\s\d.,]+$/.test(String(value))
    },
  },
}
</script>

<style lang="sass" scoped>
.LicensePlanTable
  color: #6F6F97
  overflow: hidden auto
  padding: 32px 84px
  background: #F5F5F9

  &__table
    display: flex

  h3
    position: relative
    z-index: 10
    margin-bottom: 20px

  &__feature
    flex: 4 1 150px

  &__feature-header
    padding-top: 24px
    font-size: 16px
    font-weight: 500
    height: 100px
    background: #F5F5F9
    position: sticky
    top: -32px

  &__feature-name
    display: flex
    align-items: center
    font-size: 13px
    font-weight: 500
    height: 40px
    overflow: hidden
    text-overflow: ellipsis

  &__plan
    flex: 1 1 175px
    display: flex
    justify-content: center

  &__plan-box
    width: 175px

  &__plan--active &__plan-box
    border: 1px solid rgba(0, 0, 0, 0.06)
    box-shadow: 0 4px 13px rgba(0, 0, 0, 0.04)
    border-radius: 4px

  &__plan-header
    text-align: center
    text-transform: capitalize
    position: sticky
    top: -32px
    z-index: 1
    height: 100px
    background: #F5F5F9
    min-height: 80px
    padding-top: 24px

  &__plan--active &__plan-header
    background: #ffffff
    border-radius: 4px 4px 0 0
    text-align: center
    border: 1px solid #E6E6F2

  &__plan-name
    display: block
    width: 100%
    text-align: center
    font-size: 16px
    font-weight: 500

  &__plan--active &__plan-name
    color: var(--v-primary-base, #0088FF)

  &__plan-status
    font-size: 12px
    color: #C1C1D2
    font-weight: 400
    margin-top: 8px

  &__plan-status
    font-size: 12px
    color: #C1C1D2
    font-weight: 400

  &__plan-item
    display: flex
    align-items: center
    justify-content: center
    height: 40px
    font-size: 13px

  &__asterisk
    font-size: 12px
    color: #ccc
    margin-left: 8px
    margin-right: calc((1ex + 8px) * -1)

  &__footnotes
    padding: 24px 0
    font-size: 12px
</style>
