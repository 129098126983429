<template>
  <div class="LicensePageInlinePanel">
    <h2 class="display-2 font-weight-medium text-truncate">
      {{ $t('license.FeaturesUsed') }}
    </h2>

    <LicenseFeatureProgress
      v-for="feature in features"
      :key="feature.techName"
      :tech-name="feature.techName"
      :name="feature.name"
      :per-project="feature.perProject"
      :counter-name="feature.counterName"
      :feature-blocked="feature[currentLicense.licenseType] === NOT_INCLUDED_IN_PLAN"
      :hide-when-active="feature.hideWhenActive"
      :add-more-btn="feature.addMoreBtn"
      :is-folded="expandedFeatureTechName !== feature.techName"
      @update:isFolded="updateIsFolded(feature, $event)"
    />
  </div>
</template>

<script>
import LicenseFeatureProgress from './LicenseFeatureProgress'

import { PLAN_TABLE, NOT_INCLUDED_IN_PLAN } from '../constants'

export default {
  name: 'LicensePageInlinePanel',

  components: {
    LicenseFeatureProgress,
  },

  data() {
    return {
      NOT_INCLUDED_IN_PLAN,

      expandedFeatureTechName: null,
    }
  },

  computed: {
    currentLicense() { return this.$store.state.license.current },
    licenseType() { return this.currentLicense?.licenseType },
    counters() { return this.$store.getters['license/combinedCounters'] },

    features() {
      return PLAN_TABLE.filter(feature => feature.showCounter)
    },
  },

  methods: {
    maxCount(techName) {
      return this.counters?.commonParameters[techName]?.maxCount || null
    },

    updateIsFolded(feature, isFolded) {
      if (!isFolded) {
        this.expandedFeatureTechName = feature.techName
      } else if (this.expandedFeatureTechName === feature.techName) {
        this.expandedFeatureTechName = null
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.LicensePageInlinePanel
  background: white
  min-width: 320px
  overflow: hidden auto

  h2
    padding: 32px 32px 24px
</style>
